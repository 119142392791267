import { template as template_019c49d7f34b46139bb31444f4a04536 } from "@ember/template-compiler";
const SidebarSectionMessage = template_019c49d7f34b46139bb31444f4a04536(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
