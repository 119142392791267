import { template as template_715f2c6b486d40ceacfead3be59a8324 } from "@ember/template-compiler";
const WelcomeHeader = template_715f2c6b486d40ceacfead3be59a8324(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
