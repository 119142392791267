import { template as template_02f8886d6f4c4938b8f0e5f1b7b7645f } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_02f8886d6f4c4938b8f0e5f1b7b7645f(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
